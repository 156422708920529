import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import RoutesService from '@/services/whatsapp/routes.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import AddNewWaba from './AddNewWaba/AddNewWaba.vue'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'Wabas',
  components: {
    HeaderTopDashboard,
    AddNewWaba,
  },
  data () {
    return {
      fbManagerIds: [],
      selectedFbManagerId: null,
      wabas: [],
      newWaba: {
        show: false,
      },
      debouncedInput: '',
      timeout: null,
      searchTerm: '',
      options: {},
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totals: 0,
      loading: false,
    }
  },
  computed: {
    headers () {
      return [
        { text: 'ID WABA', align: 'start', value: 'waba_id' },
        {
          text: this.$t('Nombre'),
          value: 'display_name',
          sortable: false,
        },
        {
          text: this.$t('Verificación'),
          value: 'fb_account_status',
          sortable: false,
        },
        {
          text: this.$t('Identificador del espacio'),
          value: 'template_namespace',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
    selectedFbManagerId () {
      this.getWabasAndTotalsByFbManagerId()
    },
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          routes.length
            ? this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    viewWabaTemplates (wabaId) {
      EventBus.$emit('showMultimedia', 'whatsapp', true)
      setTimeout(() => {
        EventBus.$emit('MultimediaManager::setWabaId', wabaId)
      }, 1200)
    },
    getWabasByFacebookManagerId () {
      RoutesService.getWabasByFacebookManagerId({
        facebookBusinessManagerId: this.selectedFbManagerId.facebook_business_manager_id,
      }).then((response) => {
        this.wabas = response
      })
    },
    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        facebookBusinessManagerId: this.selectedFbManagerId.facebook_business_manager_id,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getDataFromApi (params = {}) {
      RoutesService.getAllFacebookManagerId().then((response) => {
        this.fbManagerIds = response
        this.selectedFbManagerId = this.fbManagerIds[0]
        this.wabas = []
      })
    },
    getWabasAndTotalsByFbManagerId () {
      const params = this.getParams()
      RoutesService.getWabasByFacebookManagerId(params)
      .then(
        (response) => {
          this.wabas = JSON.parse(JSON.stringify(response))
          this.totals = this.wabas.length
        },
      )
    },
    openAddNewWaba () {
      this.newWaba.show = true
    },
  },
}
