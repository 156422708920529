var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-11 px-0 mt-n16",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-1 pt-0"},[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-grey mb-1 px-0"},[_c('v-card-title',{staticClass:"pt-0 pb-0 mb-0 card-title-bloque"},[_c('div',{staticClass:"w100"},[_c('p',{staticClass:"tit_card pt-2 inline"},[_vm._v(" "+_vm._s(_vm.$t("Gestionar WABAS"))+" "),_c('span',{staticClass:"subtit_card"},[_vm._v(" WhatsApp Business Accounts ")])])])]),_c('v-card-text',{staticClass:"px-0 py-0 my-0"},[_c('v-row',{staticClass:"px-4 py-3 pb-4",attrs:{"align":"center"}},[(_vm.selectedFbManagerId)?_c('v-col',{staticClass:"py-0 my-0",attrs:{"md":"7","cols":"auto"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('div',{staticClass:"bloque-1"},[_c('div',{staticClass:"grey--text mb-0 text-caption"},[_vm._v(" "+_vm._s(_vm.$t("Estado de verificación del negocio"))+" ")]),_c('div',{staticClass:"text-body-2"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"green darken-1","small":""}},[_vm._v(" fas fa-check-circle ")]),_c('b',[_vm._v(" "+_vm._s(_vm.$t( "WHATSAPP_FB_STATUS_" + _vm.selectedFbManagerId.fb_account_status.name ))+" ")])],1)]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('div',{staticClass:"bloque-2"},[_c('div',{staticClass:"grey--text mb-0 text-caption"},[_vm._v(" Facebook Manager ID ")]),_c('div',{staticClass:"text-body-1",attrs:{"id":"fbManagerIdSelector"}},[_c('v-select',{staticClass:"oulined-input mb-n10",attrs:{"items":_vm.fbManagerIds,"return-object":"","elevation":"0","solo":"","item-text":"facebook_business_manager_id","item-value":"facebook_business_manager_id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-1",attrs:{"color":"blue lighten-2","small":""}},[_vm._v(" fab fa-facebook ")]),_vm._v(" "+_vm._s(item.facebook_business_manager_id)+" ")]}}],null,false,2940198733),model:{value:(_vm.selectedFbManagerId),callback:function ($$v) {_vm.selectedFbManagerId=$$v},expression:"selectedFbManagerId"}})],1)])],1)]):_vm._e(),_c('v-col',{attrs:{"md":"3","offset":"2"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","placeholder":_vm.$t('Buscar')},model:{value:(_vm.searchTermHelper),callback:function ($$v) {_vm.searchTermHelper=$$v},expression:"searchTermHelper"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v(" fas fa-search ")])],1)],2)],1)],1),_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{
                  'items-per-page-options': [5, 10, 15, 25],
                  'disable-pagination': true,
                  'items-per-page-text': _vm.$t('Items por página'),
                },"disable-pagination":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.wabas,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totals,"options":_vm.options,"dense":"","loading":_vm.loading,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.fb_account_status",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mt-n1",attrs:{"small":"","color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" fas fa-question-circle ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Estado del Facebook Manager"))+" ")])]}},{key:"item.fb_account_status",fn:function(ref){
                var item = ref.item;
return [(item.fb_account_status.id === 0)?_c('v-icon',[_vm._v(" fas fa-check ")]):_vm._e(),(item.fb_account_status.id === 1)?_c('v-icon',[_vm._v(" far fa-clock ")]):_vm._e(),(item.fb_account_status.id === 2)?_c('v-icon',[_vm._v(" fas fa-times ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("WHATSAPP_FB_STATUS_" + item.fb_account_status.name))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-left mt-1",attrs:{"icon":"","color":"blue-grey darken-2","to":{
                          path: '/developers/waba-phone-detail?waba_id=' + item.waba_id,
                        }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-mobile-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Teléfonos"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-left",attrs:{"icon":"","color":"blue-grey darken-2"},on:{"click":function($event){return _vm.viewWabaTemplates(item.waba_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-boxes ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Plantillas"))+" ")])])]}}])})],1),_c('v-card-actions',{staticClass:"py-0 my-0 mt-lg-n13"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)],1),_c('add-new-waba',{attrs:{"new-waba":_vm.newWaba}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }